import { Injectable } from '@angular/core';
import { ConfigService } from '@ngx-config/core';
import * as moment from 'moment/moment';
import '../shared/date.interface'
import { HttpParams } from '@angular/common/http';
import { Paging } from '../entities/paging.entity';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  protected apiUrl: string;
  constructor(protected readonly config: ConfigService) {
    this.apiUrl = this.config.getSettings('Api.Url');
  }

  protected AjustarDataHora(key: any, value: any): any {
    if (typeof value === 'string' ) {
        if (moment(value, moment.ISO_8601).isValid() && value.length >=10) {
            return moment(value, moment.ISO_8601).toDate();
        }
    }
    return value;
  }

  protected MontarArrayPaginacaoOrdenacao(params : HttpParams, Paginacao: Paging) : HttpParams
    {
       
        if (Paginacao.PageIndex!=null)
        params = params.set("Pagina",Paginacao.PageIndex.toString());
        if (Paginacao.PageSize)
        params = params.set("NumeroRegistros",Paginacao.PageSize.toString());
        if (Paginacao.SortField != null && Paginacao.SortField.length > 0)
        Paginacao.SortField.forEach(texto=>{
            params =  params.append("SortFields",texto);
        });

        if (Paginacao.SortOrder != null && Paginacao.SortOrder.length > 0)
        Paginacao.SortOrder.forEach(texto=>{
            params = params.append("SortOrder",texto);
        });
        return params;

    }

}

