import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './error/error.component';


const routes: Routes = [
  { path: '', redirectTo: '/login/acesso', pathMatch: 'full' },
  { path: 'erro', component: ErrorComponent },

  {
    path: 'login', loadChildren: './login/login.module#LoginModule',

  },
  {
    path: 'nav',
    loadChildren: './nav/nav.module#NavModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
