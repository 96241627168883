import { Injectable, EventEmitter } from '@angular/core';
import { ConfigService } from '@ngx-config/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TranslateService } from '../translate';
import { BaseService } from './base.service';
import { ResultadoOperacao } from '../entities/resultadooperacao.entity';
import { Mensagem } from '../entities/mensagem.entity';
import { ItemLista } from '../entities/itemlista.entity';
import { ValidacaoUsuario } from '../entities/validacaousuario.entity';
import { DadoAutenticacao } from '../entities/dadoautenticacao.entity';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Empresa } from '../entities/empresa.entity';
import { Perfil } from '../entities/perfil.entity';
import { Usuario } from '../entities/usuario.entity';
import { AlteracaoSenha } from '../entities/alteracaosenha.entity';
import { ResetarSenha } from '../entities/resetarsenha.entity';
import { Transacao } from '../entities/transacao.entity';

@Injectable()

export class AutenticacaoService extends BaseService {



    public IdentificadorEmpresa: number;
    //public Acessos: number[] = [];
    public CodigoUsuario: number;
    public NomeUsuario: string;
    public TokenAutenticacao: string;
    public Logado: boolean = false;
    public MensagemAlerta: string = null;
    public Login: string = null;
    public Carregando: boolean = false;
    public NomeEmpresa: string = null;
    public Empresas: Empresa[] = [];
    public Perfis: number[] = [];
    public NomeFilial: string = null;
    public CodigoFilial: number = null;
    public Permissoes: Transacao[] = [];
    public TodosProprietarios: boolean = false;
    public PrazoCertidao: number;

    constructor(private http: HttpClient, readonly config: ConfigService) {
        super(config)
        this.apiUrl += 'Autenticacao';
    }
    private autenticarSubject: Subject<any> = new Subject<any>();
    PreencherAcesso(dadoautenticacao: DadoAutenticacao): void {
        this.Login = dadoautenticacao.Login;
        this.TodosProprietarios = dadoautenticacao.TodosProprietarios;
        this.NomeEmpresa = dadoautenticacao.NomeEmpresa;
        this.NomeUsuario = dadoautenticacao.NomePessoa;
        this.CodigoUsuario = dadoautenticacao.IdentificadorUsuario;
        this.IdentificadorEmpresa = dadoautenticacao.IdentificadorEmpresa;
        this.TokenAutenticacao = dadoautenticacao.Token;
        this.Perfis = dadoautenticacao.Perfis;
        this.Empresas = dadoautenticacao.Empresas;
        this.CodigoFilial = dadoautenticacao.IdentificadorFilial;
        this.NomeFilial = dadoautenticacao.NomeFilial;
        this.PrazoCertidao = dadoautenticacao.PrazoCertidao;
        sessionStorage.setItem("auth_token_ddl", dadoautenticacao.Token);
       
        this.CarregarTransacao().subscribe(d=>
            {
                this.Permissoes=d;
                this.Logado = true;
                this.autenticarSubject.next();
            })
            
      
            
    }

    VerificarAutencicacao(): Observable<any> {
        return this.autenticarSubject.asObservable();
    }

    ValidarToken(): Observable<boolean> {
        var Token = sessionStorage.getItem("auth_token_ddl");
        return this.http.get(this.apiUrl + "/ValidarToken?Token=" + Token)
            .pipe(
                map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora)));
    };


    CarregarDadoToken(): Observable<ResultadoOperacao<DadoAutenticacao>> {
        var url: string = this.apiUrl + "/CarregarDadoToken";
        return this.http.get(url)
            .pipe(
                map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora)));
    }

    RetornarEmpresas(): Observable<Empresa[]> {
        var url: string = this.apiUrl + "/RetornarEmpresas";
        return this.http.get(url)
            .pipe(
                map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora)));
    }

    LogOut(): Observable<void> {
        var url: string = this.apiUrl + "/Logout";
        return this.http.get<void>(url);

    }

    Logar(item: ValidacaoUsuario):Observable<ResultadoOperacao<DadoAutenticacao>>
    {
        var url: string = this.apiUrl + "/Login";
        return this.http.post<ResultadoOperacao<DadoAutenticacao>>(url,item)
        .pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora)));

    }

    TrocarEmpresa(item: ValidacaoUsuario):Observable<ResultadoOperacao<DadoAutenticacao>>
    {
        var url: string = this.apiUrl + "/TrocarEmpresa";
        return this.http.post<ResultadoOperacao<DadoAutenticacao>>(url,item)
        .pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora)));

    }

    AlterarSenha(item: AlteracaoSenha):Observable<ResultadoOperacao<AlteracaoSenha>>
    {
        var url: string = this.apiUrl + "/AlterarSenha";
        return this.http.post<ResultadoOperacao<AlteracaoSenha>>(url,item)
        .pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora)));

    }

    ResetarSenhaLogin(item: ResetarSenha):Observable<ResultadoOperacao<DadoAutenticacao>>
    {
        var url: string = this.apiUrl + "/ResetarSenhaLogin";
        return this.http.post<ResultadoOperacao<AlteracaoSenha>>(url,item)
        .pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora)));

    }

    ResetarSenhaCodigo(item: ResetarSenha):Observable<ResultadoOperacao<DadoAutenticacao>>
    {
        var url: string = this.apiUrl + "/ResetarSenhaCodigo";
        return this.http.post<ResultadoOperacao<AlteracaoSenha>>(url,item)
        .pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora)));

    }

    ResetarSenhaConcluir(item: ResetarSenha):Observable<ResultadoOperacao<DadoAutenticacao>>
    {
        var url: string = this.apiUrl + "/ResetarSenhaConcluir";
        return this.http.post<ResultadoOperacao<AlteracaoSenha>>(url,item)
        .pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora)));

    }

    ListarTransacoes(estruturada: boolean, apenastela: boolean):Observable<Transacao[]>
    {
        var url: string = this.apiUrl + "/ListarTrasacao/" + estruturada.toString() + '/' + apenastela.toString();

        return this.http.get(url)
            .pipe(
                map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora)));
    }

    CarregarTransacao():Observable<Transacao[]>
    {
        var url: string = this.apiUrl + "/CarregarTransacao"

        return this.http.get(url)
            .pipe(
                map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora)));
    }



    ListarEnum(Enumerador: string, CodigosIgnorar?: number[], CodigosPesquisar?: number[], OrdenarNome?: boolean): Observable<ItemLista[]> {
        let params = new HttpParams();
        var url: string = this.apiUrl + "/ListarEnum/" + Enumerador
        if (CodigosIgnorar && CodigosIgnorar.length > 0)
            CodigosIgnorar.forEach(d => {
                params = params.append("CodigosIgnorar", d.toString());
            });
        if (CodigosPesquisar && CodigosPesquisar.length > 0)
            CodigosPesquisar.forEach(d => {
                params = params.append("CodigosPesquisar", d.toString());
            });
        if (OrdenarNome)
            params = params.set("OrdenarNome", OrdenarNome.toString());

        return this.http.get<ItemLista[]>(url, { params: params });
    }

    RetornarDescricaoEnum(Enumerador: string, Codigo: number): Observable<string> {
        var url: string = this.apiUrl + "/RetornarEnum/" + Enumerador + "/" + Codigo.toString();
        return this.http.get(url, { responseType: 'text' });

    }

   

}