import { Component, OnInit, OnDestroy } from "@angular/core";
import { AutenticacaoService } from '../services/autenticacao.service';
import { ErroService } from '../services/erro.service';

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.css"]
})
export class ErrorComponent implements OnInit, OnDestroy {
  constructor(private authServ: ErroService) {}
  stack: any;
  ngOnInit() {
    let erro = this.authServ.Erros.map(d=>d.Texto).join("<br>");
    if (erro) this.stack = erro;
  }

  ngOnDestroy(): void {
    
  }
}
