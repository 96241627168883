export const LANG_PT_NAME = 'pt-br';

export const LANG_PT_TRANS = {
    "SeparadorMilhar": ".",
    "SeparadorDecimal": ",",
    "FormatoDataCampo": "dd/mm/yy",
    "ErroAplicacao": "Houve um erro na aplicação",
    "ErroAplicacao_Mensagem": "Houve um erro ao processar sua requisição Por favor tente novamente e se o problema persistir, contate um administrador.",
    "ErroAplicacao_Descricao": "Descrição do erro",
    "Pesquisa": "Filtros",
    "Selecione": "Selecione",
    "Pesquisar": "Pesquisar",
    "Sim": "Sim",
    "Nao": "Não",
    "Acao": "Ação",
    "Novo": "Novo",
    "Salvar": "Salvar",
    "ValidarErro": "Alertas",
    "Cancelar": "Cancelar",
    "Manutencao": "Manutenção",
    "Voltar": "Voltar",
    "Paginacao_a": "a",
    "Paginacao_de": "de",
    "Paginacao_registros": "registros",
    "Paginacao_registrospagina": "Mostrar linhas",
    "MensagemReservado": "Due Diligence Imobiliária é um sistema da <br />E-Fluxus. Todos direitos reservados.",
    "TituloAplicacao": "Due Diligence Imobiliária",
    "BemVindo": "Olá, seja bem vindo!",
    "AcessoConta": "Acesse a sua conta com os dados abaixo.",
    "EMail": "E-mail",
    "Senha": "Senha",
    "EsquecerSenha": "Esqueceu a senha?",
    "Acessar": "Acessar",
    "ObterCodigo": "Obter Código",
    "PrecisamosVerificarIdentidade": "Precisamos verificar sua identidade",
    "CodigoEmail": "Você receberá um código em seu e-mail",
    "VerificarIdentidade": "Verificar sua identidade",
    "InsiraCodigo": "Insira o código recebido no e-mail ",
    "InserirCodigo": "Inserir código",
    "Proximo": "Próximo",
    "RedefinirSenha": "Redefinir senha",
    "RegraSenha": "Mínimo de 8 caracteres, diferencia maiúsculas e minúsculas.",
    "NovaSenha": "Nova senha",
    "RedigitarSenha": "Redigitar senha",
    "SelecioneEmpresa": "Selecione a empresa",
    "Empresa": "Empresa",
    "Confirmar": "Confirmar",
    "Inicio": "Início",
    "Operacoes": "Operações",
    "Clientes": "Clientes",
    "Gestao": "Gestão",
    "DadosMatricula": "Dados Matrícula",
    "Grupo": "Grupo",
    "Officer": "Officer",
    "Analista": "Analista",
    "AreaSegmento": "Área Segmento",
    "Diretorio": "Diretoria",
    "ControleEmpresa": "Controle de empresas",
    "ControleUsuario": "Controle de usuários",
    "ControlePerfil": "Controle de perfil",
    "Nome": "Nome",
    "Codigo": "Código",
    "Descricao": "Descrição",
    "NomeSistema": "DueDiligence",
    "ConfirmarExclusaoGrupo": "Confirma a exclusão do grupo {0}?",
    "ConfirmarExclusaoDiretoria": "Confirma a exclusão da diretoria {0}?",
    "ConfirmarExclusaoAreaSegmento": "Confirma a exclusão da área segmento {0}?",
    "ConfirmarExclusaoUsuario": "Confirma a exclusão do usuário {0}?",
    "ConfirmarExclusaoPerfil": "Confirma a exclusão do perfil {0}?",
    "ConfirmarExclusaoEmpresa": "Confirma a exclusão da empresa {0}?",
    "ConfirmarExclusaoFilial": "Confirma a exclusão da filial {0}?",
    "ConfirmarExclusaoCliente": "Confirma a exclusão do cliente {0}?",
    "AlertaCCIR": "Na hipótese do imóvel ter mais de um proprietário, favor proceder a busca das certidões dos demais proprietários inserindo o(s) número(s) do(s) CPF(s) e/ou CNPJ(s) correspondente(s) na opção \"Pesquisar Certidões\".",
    "Filiais": "Filiais",
    "Certidoes": "Certidões",
    "PossuiFilial": "Possui Filial",
    "ConfirmarSenha": "Confirmação Senha",
    "ConfirmarExclusaoOperacao": "Confirma Exclusão da operação {0}?",
    "LogAcesso": "Log de Acesso",
    "LogAlteracao": "Log de Alterações",
    "InformacoesGerais": "Informações Gerais",
    "Matriculas": "Matrículas",
    "ClienteNaoEncontradoDocumento": "Não existe cliente com o documento informado",
    "AnalisarMatricula": "Analisar Matrícula",
    "TipoImovel": "Tipo do Imóvel",
    "Proprietario": "Proprietário",
    "NomeProprietario": "Nome Proprietário",
    "ImpactoLiquidez": "Impacto na Liquidez",
    "EnviarArquivoMatricula": "Selecione os arquivos de matrícula em formato pdf a sersem analisados.",
    "AddAqquivoComputador": "Adicionar um arquivo do seu computador",
    "ArrasteArquivo": "Clique ou arraste e solte seu arquivo",
    "SelecionarArquivoEnvio": "Deve ser selecionado um arquivo para envio",
    "VerDetalhes": "Ver Detalhes",
    "DetalhesMatricula": "Detalhes da matrícula",
    "MatriculaRegistroImovel": "Matrícula de Registro de Imóvel",
    "AtualizarMatricula": "Atualizar Matrícula",
    "NumeroMatricula": "Número da Matrícula",
    "CodigoIncra": "Código do Imóvel(INCRA)",
    "NIRF": "NIRF",
    "AreaHa": "Área (ha)",
    "NumeroContribuinte": "Número do Contribuinte",
    "NaoIdentificado": "Não Identificado",
    "NaoAplica": "Não se Aplica",
    "Proprietarios": "Proprietários",
    "AverbacoesRegistros": "Averbações e Registros",
    "NumAto": "° Ato",
    "Abertura": "Abertura",
    "Valido": "Válido",
    "Invalido": "Inválido",
    "DeixeComentarios": "Deixe seus comentários aqui",
    "CalculadoraHipoteca": "Calculadora de Hipotecas",
    "HistoricoHipotecas": "Histórico de Hipotecas",
    "Ato": "Ato",
    "Data": "Data",
    "Evento": "Evento",
    "CredorEmitente": "Credor/Emitente",
    "Valor": "Valor",
    "ValorTotalGarantido": "Valor total histórico garantido por Hipotecas",
    "QuantidadeHipoteca": "Quantidade de Hipotecas existentes",
    "Area": "Área",
    "Parecer": "Parecer",
    "Estatisticas": "Estatísticas",
    "HistoricoArea": "Histórico de área",
    "QuantidadePaginas": "Quantidade de Páginas",
    "TempoOCR": "Tempo de OCR",
    "NumeroDocumentos": "Quantidade de Documentos",
    "TempoAnalise": "Tempo de Análise",
    "TempoTotalProcessamento": "Tempo Total de Processamento",
    "NivelConfiancaOCR": "Nível de Confiança OCR",
    "Editado": "Editado",
    "CCIR": "CCIR",
    "CAR": "CAR",
    "NomeTipoCertidao": "Tipo de Certidão",
    "ArquivoMatricula": "Arquivo Matrícula",
    "Ambiental": "Ambiental",
    "OnusImpactoLiquidez": "Ônus, gravames ou condições que impactem a liquidez e/ou transferência do imóvel",
    "TipoConsulta": "Tipo Consulta",
    "ValorGarantidoAlienacaoFiduciaria": "Valor garantido por alienação fiduciária",
    "CredorAlienacaoFiduciaria": "Credor da alienação fiduciária",
    "ReservaLegalAverbada": "Reserva legal averbada",
    "CliqueAquiParaVerAto": "Clique aqui para ver o ato",
    "UsuarioVerAlerta": "Visualizar Alerta De",
    "VerAlerta": "Visualizar Alerta",
    "UsuarioVerAlertaTitulo": "Configurar Usuários ver Alerta",
    "Sair": "Sair",
    "AlterarSenha": "Alterar Senha",
    "SenhaAtual": "Senha Atual",
    "DataAcessoDe": "Data Acesso De",
    "DataAcessoAte": "Data Acesso Até",
    "Erro": "Erro",
    "Sucesso": "Sucesso",
    "Usuario": "Usuário",
    "DataDe": "Data De",
    "DataAte": "Data Até",
    "Rotina": "Rotina",
    "Chave": "Chave",
    "Detalhes": "Detalhes",
    "Chaves": "Chaves",
    "Campo": "Campo",
    "ValorAnterior": "Valor Anterior",
    "ValorAtualizado": "Valor Atualizado",
    "RegistrosFilhos": "Registros Filhos",
    "CamposModificados": "Campos Modificados",
    "PesquisarCertidoes": "Pesquisar Certidões",
    "PesquisarCertidao": "Pesquisar certidão",
    "NaoExistemDados": "Não existem dados à serem apresentados.",
    "Certidao": "Certidão",
    "Status": "Status",
    "DataEmissao": "Data Emissão",
    "PrazoValidade": "Prazo Validade",
    "Arquivo": "Arquivo",
    "AtualizarCertidao": "Atualizar Certidão",
    "BaixarSelecionados": "Baixar Selecionados",
    "AtualizarSelecionados": "Atualizar Selecionados",
    "DenominacaoImovel": "Denominação do Imóvel Rural",
    "Municipio": "Município",
    "Situacao": "Situação",
    "CondicaoCadastro": "Condição Cadastro",
    "SituacaoCadastro": "Situação Cadastro",
    "Responsavel": "Responsável",
    "NumeroRIP": "No. RIP",
    "RegimeUtilizacao": "Regime de Utilização",
    "AreaTotalHa": "Área Total(ha)",
    "ValorTotal": "Valor Total",
    "CodigoImovelRural": "Código do Imóvel Rural",
    "Condicao": "Condição",
    "ClassificacaoFundiaria": "Classificação Fundiária",
    "Exercicio": "Exercício",
    "NomeDeclarante": "Nome Declarante",
    "ErroConsultaMatricula": "Erro no processamento do arquivo {0} para a operação {1}. O erro retornado foi {2}",
    "ErroConsultaCertidao": "Erro na consulta das certidões da operação {0}. O erro retornado foi {1}",
    "ErroConsultaCertidaoMatricula": "Erro na consulta das certidões da operação {0} para a matrícula {1}. O erro retornado foi {2}",
    "SucessoConsultaMatricula": "Matrícula {0} processada com sucesso para operação {1}",
    "SucessoConsultaCertidao": "Consulta de Certidões concluída com sucesso para operação {0}",
    "SucessoConsultaCertidaoMatricula": "Consulta de Certidões concluída com sucesso para matrícula {1} da operação {0}",
    "ExpiracaoCertidao": "Expiração da certidão de {0}",
    "ExpiracaoCertidaoTipo": "A certidão <b>{0}</b> expira em <b>{1}</b>",
    "ConsultaCertidao": "Certidões para operação {0}",
    "ConsultaCertidaoMatricula": "Certidões para matrícula {1} da operação {0}",
    "Rotulo": "Rótulo",
    "Historico": "Histórico",
    "IdentificarMudancaProprietario": "Identificar Mudança Proprietário",
    "IdentificarMudancaValorFinanceiro": "Identificar Mudança Valor Financeiro",
    "IdentificarAreaRelacionada": "Identificar Área Relacionada",
    "IdentificarReferenciaAtoAnterior": "Identificar Referência Ato Anterior",
    "IdentificarCredor": "Identificar Credor",
    "ContabilizarNovaHipoteca": "Contabilizar Nova Hipoteca",
    "ContabilizarCancelamentoEvento": "Contabilizar Cancelamento Evento",
    "Destacar": "Destacar",
    "UltimaAlteracao": "Última Alteração",
    "IdentificarDadosMatricula": "Identificar dados na matrícula",
    "NovoEvento": "Novo Evento",
    "Identificacao": "Identificação",
    "Acoes": "Ações",
    "Notificacoes": "Notificações",
    "NrMatricula": "N° Matrícula",
    "AlterarSituacao": "Alterar Situação",
    "TextoReservado": "Due Diligence Imobiliária é um sistema da E-Fluxus. Todos direitos reservados.",
    "Estoque": "Estoque",
    "ResumoPeriodo": "Resumo por período",
    "ResumoPeriodoVolumetria": "Resumo por período / volumetria",
    "TodasAreas": "Todas Áreas",
    "Periodo": "Período",
    "EstoqueAtual": "Estoque atual",
    "EstoqueAnterior": "Estoque anterior",
    "Entradas": "Entradas",
    "Saidas": "Saídas",
    "Canceladas": "Canceladas",
    "SaidaCanceladas": "Saídas + Canceladas",
    "EstoqueAnteriorEntrada": "Estoque anterior + Entradas",
    "Concluidas": "Concluídas",
    "NaoConcluidas": "Não Concluídas",
    "Efetivadas": "Efetivadas",
    "Corretas": "Corretas",
    "Editadas": "Editadas",
    "Efetivadas_Sucesso": "Efetivadas/Sucesso",
    "EnviarFeedback": "Enviar Feedback",
    "Mensagem": "Mensagem",
    "MotivoContato": "Motivo do Contato",
    "Anexo": "Anexo",
    "Ref.": "Ref.",
    "SugerimosVerificarAMatricula": "Sugerimos verificar a matrícula pois pode haver atos que não identificamos",
    "TranscrissaoMatriculaAnterior": "Transcrissão Matrícula Anterior",
    "TranscrissaoMatriculaAnteriorCalculo": "Favor verificar o cálculo da hipoteca, pois existem referências a matrículas anteriores",
    "ReferenciaNaoIdentificada": "Referência não identificada",
    "AtoNaoIdentificado": "º ato(não identificado)",
    "ReferenciaEncontradaAbertura": "Referência encontrada na abertura",
    "RegimeMatrimonial": "Regime Matrimonial",
    "HipotecaDe": "Hipoteca de",
    "NumGrau": "º grau",
    "Credor": "Credor",
    "de": "de",
    "ValorVenalDe": "Valor Venal de ",
    "BaseCalculoITBI": "Base de cálculo ITBI ",
    "PesquisarApenasPendentesVencidas": "Certidões ainda não pesquisadas ou certidões vencidas",
    "PesquisarDadosResultadoCertidao": "Utilizar Resultado para Completar Certidões",
    "ConfirmarExclusaoCertidao": "Confirma a exclusão da certidão?",
    "Exportar": "Exportar",
    "HistoricoCertidoes": "Resumo Histórico de Certidões",
    "HistoricoMatriculas": "Resumo Histórico de Matrículas",
    "NaoExistemRequisicoesPeriodo": "Não existem requisições no período pesquisado",
    "Quantidade": "Quantidade",
    "NomeArquivo": "Nome do Arquivo",
    "Paginas": "Páginas",
    "HistoricoPesquisa": "Resumo Histórico de Pesquisa de Certidões",
    "ParametrosPesquisa": "Parâmetros da Pesquisa",
    "ProprietariosParecer": "Proprietários no Parecer",
    "TodosProprietarios": "Todos",
    "UltimoAtoProprietarios": "Último Ato",
    "DescricaoImovel": "Descrição do Imóvel",
    "UltimoAditamentoAto": "(Último aditamento no {0}° Ato)",
    "TransporteMatriculaAnteriorAbertura": "Atenção: Verifique possíveis atos na Abertura da matrícula!",
    "TituloEstabelecimento": "Título Estabelecimento (Fantasia)",
    "NumeroInscricaoEstadual": "N° Inscrição Estadual",
    "CNAEFPrincipal": "CNAE-F Principal",
    "SituacaoCadastral": "Situação Cadastral",
    "CNAEFSecundario": "CNAE-F Secundária",
    "CertidoesMatricula": "Certidões para Matrícula",
    "MeusFeedbacks": "Meus Feedbacks",
    "DemandaResolvida": "Demanda Resolvida",
    "Pendente": "Demanda Pendente",
    "DataCadastro": "Data Cadastro",
    "Resposta": "Resposta",
    "DataResposta": "Data da Resposta",
    "ControleFeedbacks": "Controle de Feedbacks",
    "ResponderFeedback": "Responder Feedback",
    "TempoResposta": "Tempo de Resposta",
    "TempoPendencia": "Tempo de Pendência",
    "PossuiAnexo": "Possui Anexo",
    "FeedbacksUsuario": "Feedbacks por usuário",
    "FeedbacksMotivo": "Feedbacks por motivo",
    "FeedbacksSituacao": "Feedbacks por situação",
    "FeedbacksAnexo": "Feedbacks com anexos",
    "ComAnexo": "Com Anexo",
    "SemAnexo": "Sem Anexo",
    "ResumoFeedbacks": "Resumo Feedbacks",
    "IdentificarVendaCommodity": "Indentificar Venda de Commodity",
    "IdentificarMudancaArea": "Identificar Mudança de Área",
    "BuscaExata": "Busca Exata",
    "NumeroCertidoes": "Quantidade de Certidões",
    "SegundosResultado": "Tempo Processamento (Segundos)",
    "TempoTotalSegundos": "Tempo Total(Segundos)",
    "TempoMedioSegundos": "Tempo Médio(Segundos)",
    "DashboardTemposProcessamento": "Tempos de Processamento",
    "QuantidadeRequisicoes": "Quantidade de Requisições",
    "PercentualSucesso": "Percentual de Sucesso",
    "TempoProcessamento": "Tempo de Processamento",


    "ConfiguracaoIA": "Configuração IA",
    "Id": "Id",
    "FormatoId": "Id Formato",
    "Indice": "Índice",
    "Cor": "Cor",
    "DocumentoTag": "Documento Tag",
    "DocumentoFormato": "Documento Formato",
    "DocumentoFormatoTag": "Documento Tag",
    "VisualizarDocumento": "Visualizar Documento",
    "Tags": "Tags",
    "ImportarArquivo": "Importar Arquivo",
    "UploadDocumento": "Upload Documento",
    "ImportarDocumento": "Importar Documento",
    "DocumentoVersaoId": "Documento Versão Id",
    "DocumentoId": "Documento Id",
    "DocumentoFormatoNome": "Formato",
    "DocumentoNome": "Documento Nome",
    "DocumentoVersao": "Documento Versão",
    "TagsPreTreino": "Tags Pré Treino",
    "FormatoTagId": "Formato Tag Id",
    "Fim": "Fim",
    "TagsResultado": "Resultado",
    "Submeter": "Submeter",
    "TipoTexto": "Texto",
    "TipoPDF": "PDF",
    "ConfirmarExclusaoTag": "Confirma a exclusão do Tag {0}?",
    "TextoTag": "Texto Tag",

    "ArquivoLivro3": "Arquivo Livro 3",
    "Livro3": "Livro 3",
    "Livro": "Livro",
    "Documentos": "Documentos",    
    "AnalisarDocumento": "Analisar Documento",
    "DetalhesDocumento": "Detalhes do documento",
    "DetalhesCertidao": "Detalhes do certidão",
    "DadosDocumento": "Dados Documento",
    "Cartorio": "Cartório",
    "SituacaoDocumento": "Situação",
    "Produtor": "Produtor",
    "RegistroAverbacao": "Registro / Averbação",
    "Calculadora": "Calculadora",
    "Encontrado": "Encontrado",
    "Titulo": "Título",
    "DataEmissaoTitulo": "Data Emissão",
    "DataVencimentoTitulo": "Data Vencimento",
    "DocumentoProdutor": "Documento Produtor",
    "NomeProdutor": "Nome Produtor",    
    "NumeroCertidao": "Número da Certidão",
    "NomeDocumento": "Nome do documento",

    "Busca": "Busca",
    "Referencia": "Referência",

    "REGISTRO_AVERBACAO": "Registro averbação",
    "MATRICULA": "Matrícula",
    "GARANTIA": "Garantia",
    "SAFRADATA": "Data da Safra",
    "SAFRAPRODUTO": "Safra produto",
    "SAFRATIPO": "Safra tipo",
    "TIPOSAFRA": "Tipo Safra",
    "LOCAGRAO": "Local grão",
    "LOCAL_BEM": "Local bem",

    "NumeroAverbacao": "Registro / Averbação",
    "RegistroAverbacaoData": "Data Registro / Averbação",
    "Garantia": "Garantia",
    "Produto": "Produto",
    "Especificacao": "Especiaficação",
    "SafraAno": "Ano da Safra",
    "SafraTipo": "Tipo da Safra",
    "QuantidadeEmKG": "Quantidade em KG(s)",
    "QuantidadeEmSacas": "Quantidade em Saca(s)",
    "ValorTitulo": "Valor",    
    "Matricula": "Matrícula",
    "LocalBem": "Local Bem",
    "Endosso": "Endosso",
    "ImovelProprietario": "Imóvel Proprietário",

    "SafraData": "Data da Safra",
    "TotalKG": "Total KG",
    "TotalValor": "Total Valor",

    "ContratoSocial": "Contrato Social",
    "AnalisarContratoSocialDocumento": "Analisar Contrato Social",
    "DadosEmpresa": "Dados Empresa",
    "DadosSocios": "Dados Sócios",
    "ComposicaoCapital": "Composição Capital",
    "Administracao":"Administração",
    "Administrador": "Administrador",
    "PrazoMandato": "Prazo Mandato",
    "Procurador": "Procurador",
    "PrazoProcuracao": "Prazo Procuração",
    "Atribuicoes": "Atribuições",

    "PODERES": "Poderes",
    "FORMAREPRESENTACAO": "Forma de Representação",
    "AUTORIZACAO": "Autorização",
    "VEDACAO": "Vedação",
    "RESTRICAO": "Restrição",
    "LIMITES": "Limites",
    "ALCADAS": "Alçadas",
    "OMISSAO": "Omissão",
    "ATODELIBERACAO": "Ato / Deliberação",

    "ArquivoContratoSocial": "Contrato Social",

    "TipoSafra": "Tipo da Safra",
    "DataRegistroAverbacao": "Data Registro / Averbação",    
    "AnoSafra": "Ano Safra",
    "QtdKG": "Quantidade KG",
    "QtdUni" : "Quantidade / Unidade",
    "QtdSacas": "Quantidade Sacas",
    "QtdKGsCalculada": "Quantidade KGs Calculada",
    "ND" : "Não Identificado",
    "NDisponivel": "Não Disponível",
    "ValorGarantia" : "Valor Garantia",
    "PrazoCertidao" : "PRAZO ACEITÁVEL",

    "ExportarPDF": "Exportar PDF",
    
    "QuantidadeCalculada" : "Quantidade Calculada",
    "Observacao" : "Observação",
    "ObservacaoTotal" : "Observação Total",
    "DataEmissaoVencCertidão" : "Data Emissão/ Vencimento Certidão",
    "CalculadoraConsolidada" : "Calculadora Consolidada",
    "TotalSacas" : "Total em Sacas",
    "TotalKgs" : "Total em KGs",
    "ArquivoRegex": "Arquivo não condiz com a nomenclatura padrão: {0}",
    "ArquivoExtensaoDiferente": "Somente pode ser importado arquivos com a mesma extensão"
};


export const PT_Calendar = {
    firstDayOfWeek: 1,
    dayNames: ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"],
    monthNamesShort: ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"]
}