import { NgModule } from '@angular/core';
import { TranslatePipe } from './translate.pipe';

@NgModule({
    imports: [],
    declarations: [TranslatePipe],
    exports: [
        TranslatePipe
    ],
    providers: []
})
export class TranslateModule {

    static forRoot() {
        return {
            ngModule: TranslateModule
        };
    }
}
