import { Injectable } from '@angular/core';
import { ConfigService } from '@ngx-config/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResultadoOperacao } from '../entities/resultadooperacao.entity'
import { BaseService } from '../services/base.service';

@Injectable()
export class ReportService extends BaseService {
    // private formatos: any[] = this._utilities.getFormatos();
    public reportAction: ReportActions;

    constructor(private http: HttpClient, readonly config: ConfigService) {
        super(config)
        this.apiUrl += 'Relatorio';
        this.reportAction = ReportActions.None;
    }

    public setActionName(action: ReportActions) {
        this.reportAction = action;
    }

    public open(title: string, model: any): Observable<boolean>;
    public open(title: string, model: any, grouped: boolean): Observable<boolean>;
    public open(title: string, model: any, grouped: boolean = false): Observable<boolean> {
        model.Relatorio = ReportActions[this.reportAction].replace('Lote', '');
        let metodo = grouped ? 'GetRelatorioAgrupado' : 'GetRelatorio';
        var url: string = this.apiUrl + '/BaixarArquivo/';

        return this.http.post<ResultadoOperacao<Int8Array>>(url, model).pipe(
            map(res => { 
                if (res) {
                    let formato = {
                      MimeType: "application/pdf",
                      Extensao: "pdf"
                    };
                    // this.formatos.find(r => r.Id == model.Formato);
                    let file = new Blob([this._base64ToArrayBuffer(res)], { type: formato.MimeType });
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(file, title + "." + formato.Extensao);
                    } else {
                        let fileURL = URL.createObjectURL(file);
                     
                        if (formato.Extensao == "pdf" && !model.Identificador)
                           window.open(fileURL, title + "." + formato.Extensao);
                        else {
                            let elementA: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
                            elementA.href = fileURL;
                            elementA.download = title + "." + formato.Extensao;
                            document.body.appendChild(elementA);
                            elementA.click();        
                            document.body.removeChild(elementA);
                        }
                    }
                    
                    return true;
                }
                
                return false;
            }));
    }

    public _base64ToArrayBuffer(base64) {
        var binary_string =  window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array( len );
        for (var i = 0; i < len; i++)        {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes;
    }
}

export enum ReportActions {
    None = 0,

    Certidao_Positiva = 1,
    Certidao_Negativa = 2
}