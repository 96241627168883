import * as moment from 'moment/moment';
moment.locale('pt-br');

Date.prototype.toISOString = function () {  return moment(this).utcOffset(0,true).toJSON(); }
Date.prototype.truncate = function(this: Date): Date {
  return new Date(this.getFullYear(), this.getMonth(), this.getDate());
}

Date.prototype.subtractTimezone = function(this: Date): Date {
  this.setTime(this.getTime() - this.getTimezoneOffset()*60*1000)
  return this;
}

Date.prototype.setHourMinute = function(this: Date, value: string): Date {
  var hora = moment(value, ['HH:mm', 'HHmm']).hour();
  var minuto = moment(value, ['HH:mm', 'HHmm']).minute();
  return new Date(this.getFullYear(), this.getMonth(), this.getDate(), hora, minuto);
}

Date.prototype.addHour = function(this: Date, value: number): Date {
  return new Date(this.getFullYear(), this.getMonth(), this.getDate(), this.getHours() + value, this.getMinutes());
}

Date.prototype.addDate = function(this: Date, value: number): Date {
  return new Date(this.getFullYear(), this.getMonth(), this.getDate() + value);
}

Date.prototype.addMonth = function(this: Date, value: number): Date {
  return new Date(this.getFullYear(), this.getMonth() + value, this.getDate());
}

Date.prototype.getFirstDateOfMonth = function(this: Date): Date {
  return new Date(this.getFullYear(), this.getMonth(), 1);
}

Date.prototype.getLastDateOfMonth = function(this: Date): Date {
  return new Date(this.getFullYear(), this.getMonth(), 1).addMonth(1).addDate(-1);
}

Date.prototype.format = function(this: Date, format: string): string {
  return moment(this).format(format);
}

Date.convert = function(value: string): Date {
  let date = moment(value).toDate();
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  return date.truncate();
}

declare module 'moment' {
    export interface Moment {        
        toJSON(): string;
    }
  }


  (moment.fn as any).toJSON = function () {

    const _self = this as moment.Moment;
    return moment(_self).utcOffset(0,true).format();
  };