import { Injectable } from '@angular/core';
import { Mensagem } from '../entities/mensagem.entity';

@Injectable()

export class ErroService
{    
    public Erros: Mensagem[] = [];



}