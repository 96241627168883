import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from './translate';
import { AutenticacaoService } from './services/autenticacao.service';
import './shared/date.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private router: Router, public _translate: TranslateService, public srvAutenticacao: AutenticacaoService) {
    this._translate.use("pt-br");
  }

  public ngOnInit() {
    if (sessionStorage.getItem('auth_token_ddl')) {
      this.srvAutenticacao.ValidarToken().subscribe(v => {
        if (v) {
          this.srvAutenticacao.CarregarDadoToken().subscribe(d => {
            if (d && d.Sucesso) {
              this.srvAutenticacao.PreencherAcesso(d.ItemRegistro)
            }
          });
        }

        else {
          sessionStorage.removeItem("auth_token_ddl");
          this.router.navigate(["login/acesso"]);
        }

      });


    }
    else
      this.router.navigate(["login/acesso"]);

  }
}
