import { Observable, throwError, of } from "rxjs";
import { Injectable, isDevMode } from "@angular/core";
import { HttpInterceptor } from "@angular/common/http";
import { HttpRequest } from "@angular/common/http";
import { HttpHandler } from "@angular/common/http";
import { HttpEvent } from "@angular/common/http";
import { HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { catchError } from 'rxjs/internal/operators';
import { Router } from '@angular/router';
import { AutenticacaoService } from './autenticacao.service';
import { Mensagem } from '../entities/mensagem.entity';
import { ErroService } from './erro.service';

@Injectable()
export class AutenticacaoInterceptor implements HttpInterceptor {
  constructor(private router: Router, private srvErro: ErroService ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem("auth_token_ddl");
    // HttpHeader object immutable - copy values
    const headerSettings: { [name: string]: string | string[] } = {};

    for (const key of request.headers.keys()) {
      headerSettings[key] = request.headers.getAll(key);
    }

    if (token && request.url.indexOf("ValidarToken") < 0) {
      headerSettings["Authorization"] = "Bearer " + token;
    }
    headerSettings["Content-Type"] = "application/json";

    const newHeader = new HttpHeaders(headerSettings);
    const dupReq = request.clone({
      headers: newHeader
    });
    return next.handle(dupReq)
      .pipe(
        catchError(error => {

          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              sessionStorage.removeItem("auth_token_ddl");
              this.router.navigate(['login']);
              return of(error as any);
            }
            if (error.status === 500)
            { 
            const applicationError = error.headers.get("Application-Error");
            if (applicationError) {
              if (!isDevMode())
              {
                this.srvErro.Erros = [<Mensagem>{ Texto: applicationError}];

                this.router.navigate(['erro']);
              }
              return throwError(applicationError);
            }
            const serverError = error.error;
            let modalStateErrors = "";
            if (serverError && typeof serverError === "object") {
              for (const key in serverError) {
                if (serverError[key]) {
                  modalStateErrors += serverError[key] + "\n";
                }
              }
            }
            if (!isDevMode())
            {
              this.srvErro.Erros = [<Mensagem>{ Texto: modalStateErrors || serverError || "Server Error" }];
              this.router.navigate(['erro']);
            }
            return throwError(modalStateErrors || serverError || "Server Error");
            }
            return throwError(error);
          }
        })
      );

  }

}
