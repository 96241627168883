import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription, timer, EMPTY } from "rxjs";
import {  debounce } from 'rxjs/operators';
import { LoadingScreenService } from '../../services/loading-screen.service';

@Component({
  selector: "app-loading-screen",
  templateUrl: "./loading-screen.component.html",
  styleUrls: ["./loading-screen.component.scss"]
})
export class LoadingScreenComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  loadingSubscription: Subscription;

  constructor(private loadingScreenService: LoadingScreenService) {}

  ngOnInit() {
    this.loadingSubscription = this.loadingScreenService.loadingStatus
      .pipe(debounce(ev => ev === true ? timer(1000) : EMPTY))
      .subscribe(value => {
        this.loading = value;
      });
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }
}
