export const LANG_EN_NAME = 'en-us';

export const LANG_EN_TRANS = {
    "SeparadorMilhar": ",",
    "SeparadorDecimal": ".",
    "FormatoDataCampo": "mm/dd/yy",
    "ErroAplicacao": "An error has ocurred in the application",
    "ErroAplicacao_Mensagem": "An error happened during your request. Please try again, and if it persists contact system's responsable.",
    "ErroAplicacao_Descricao": "Error description",
    "Pesquisa": "Filters",
    "Selecione": "Select",
    "Pesquisar": "Search",
    "Sim": "Yes",
    "Nao": "No",
    "Acao": "Action",
    "Novo": "New",
    "Salvar": "Save",
    "ValidarErro": "Alerts",
    "Cancelar": "Cancel",
    "Manutencao": "Maintenance",
    "Voltar": "Back",
};
