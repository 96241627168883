import { Injectable, EventEmitter } from '@angular/core';
import { ConfigService } from '@ngx-config/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TranslateService } from '../translate';
import { BaseService } from './base.service';
import { ResultadoOperacao } from '../entities/resultadooperacao.entity';
import { Mensagem } from '../entities/mensagem.entity';
import { ItemLista } from '../entities/itemlista.entity';
import { ValidacaoUsuario } from '../entities/validacaousuario.entity';
import { DadoAutenticacao } from '../entities/dadoautenticacao.entity';
import { Observable, Subject } from 'rxjs';
import { enumTipoImovel } from '../domains/enumtipoimovel.enum';
import { BDMWorkflow } from '../entities/bdmworkflow.entity';
import { CertidaoReload } from '../entities/certidaoreload.entity';

@Injectable()

export class PlataformaService extends BaseService {

    constructor(private http: HttpClient, readonly config: ConfigService) {
        super(config)
        this.apiUrl += 'Autenticacao';
       
    }

    public Nome: string = "";
    public Ambiente: string = null;
    
  

    ListarEnum(Enumerador: string, CodigosIgnorar?: number[], CodigosPesquisar?: number[], OrdenarNome?: boolean): Observable<ItemLista[]> {
        let params = new HttpParams();
        var url: string = this.apiUrl + "/ListarEnum/" + Enumerador
        if (CodigosIgnorar && CodigosIgnorar.length > 0)
            CodigosIgnorar.forEach(d => {
                params = params.append("CodigosIgnorar", d.toString());
            });
        if (CodigosPesquisar && CodigosPesquisar.length > 0)
            CodigosPesquisar.forEach(d => {
                params = params.append("CodigosPesquisar", d.toString());
            });
        if (OrdenarNome)
            params = params.set("OrdenarNome", OrdenarNome.toString());

        return this.http.get<ItemLista[]>(url, { params: params });
    }

    RetornarDescricaoEnum(Enumerador: string, Codigo: number): Observable<string> {
        var url: string = this.apiUrl + "/RetornarEnum/" + Enumerador + "/" + Codigo.toString();
        return this.http.get(url, { responseType: 'text' });

    }

    ListarWorkflow(TipoImovel: enumTipoImovel):Observable<BDMWorkflow[]>{
        let params = new HttpParams();
        var url: string = this.apiUrl + "/RetornarWorflows" 
       
        if (TipoImovel)
            params = params.set("TipoImovel", TipoImovel.toString());

        return this.http.get<BDMWorkflow[]>(url, { params: params });
    }




}