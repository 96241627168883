import { InjectionToken } from '@angular/core';

// import translations
import { LANG_PT_NAME, LANG_PT_TRANS, PT_Calendar } from './lang-pt';
import { LANG_EN_NAME, LANG_EN_TRANS } from './lang-en';

// translation token
export const TRANSLATIONS = new InjectionToken('translations');

// all translations
export const dictionary = {
    [LANG_PT_NAME]: {Textos:LANG_PT_TRANS, Calendar: PT_Calendar},
    [LANG_EN_NAME]: {Textos: LANG_EN_TRANS, Calendar: PT_Calendar}
};

// providers
export const TRANSLATION_PROVIDERS = [
    { provide: TRANSLATIONS, useValue: dictionary },
];