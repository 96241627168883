import { Injectable, Component } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AutenticacaoService } from "../services/autenticacao.service"
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationGuard implements CanActivate {

    constructor(private _router: Router, private autenticacaoService: AutenticacaoService) { }

    public canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean | Observable<boolean> {

        if (this.autenticacaoService.Logado) {
            if (sessionStorage.getItem('auth_token_ddl')) {
                return new Observable<boolean>((observer) => {
                    var PossuiAcesso = true;
                    if (route.data["codigoAcesso"]) {
                        PossuiAcesso = this.autenticacaoService.Permissoes.some(d => d.Codigo == route.data["codigoAcesso"]);
                    }
                    observer.next(PossuiAcesso);
                    observer.complete();

                });
            }
            else {
                return new Observable<boolean>((observer) => {
                    observer.next(false);
                    observer.complete();
                });
            }
        }
        else {
            return new Observable<boolean>((observer) => {
                this.VerificarCarregar().then(() => {
                    var PossuiAcesso = true;
                    if (route.data["codigoAcesso"]) {
                        PossuiAcesso = this.autenticacaoService.Permissoes.some(d=> d.Codigo ==route.data["codigoAcesso"]);
                    }
                    observer.next(PossuiAcesso);
                    observer.complete();

                });
            });

        }


    }

    private VerificarCarregar(): Promise<any> {
        return new Promise((callback) => this.VerificarUsuarioCarregado(callback));
    }

    private VerificarUsuarioCarregado(callback: () => void): void {
        if (!this.autenticacaoService.Logado) {
            setTimeout(() => {
                this.VerificarUsuarioCarregado(callback);
            }, 1000);

        }
        else callback();
    };
}

